.navbar-custom {
    background-color: #000033;
  }
  .bg-svc {
    background-color: #000033;
    border-color: #000033;
    color:white
  }
  .svc-app-button {
    background-color: white;
    border-color: white;
    color:white
  }
  .svc-top-button {
    background-color: #000033;
    border-color: #000033;
    color:white
  }
  .svc-top-button:focus {
    background-color: #000033;
    border-color: #000033;
    color:white;
    outline: none;
    border-style: none;
    border-width:1px;
    box-shadow:none;
  }
    .svc-top-button:hover {
      background-color: #ced1e4;
      border-color: #000033;
      color:#000033;
      outline: none;
      border-style: none;
      border-width:1px;
      box-shadow:none;
  }
  .svc-side-button {
    display: inline-flex;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    color:black;
    align-items: left;
    align-content: left;
    text-align: left;
    padding-left: 10px;
  }
  .svc-side-button:hover {
    background-color: #000033;
    border-color: #000033;
    color:white;
  }
  .svc-side-button:focus {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    color:black;
    outline: none;
    border-style: none;
    border-width:1px;
    box-shadow:none;
  }

  .svc-side-button-main {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    color:black;
    text-align: left;
  }

  .svc-side-button-main:hover {
    background-color: #000033;
    border-color: #000033;
    color:white;
  }

  .appbarIconButton {
    border:none;
    border-style:none;
    outline: none;
    box-shadow: none;
    background-color:white;
    border-color:white;
    height: "30px";
    width: "30px"
  }
  .appbarIconButton:hover {
    background-color:  #e9ecef;
  }

  .appbarImage{
    height: "30px";
    width: "30px"
  }
  .appbarHeading {
    color:black;
    font-size:medium;
    padding-right: 10px;
    padding-bottom: 1px;
    text-align: center;
  }

  .topbarIconButton {
    border:none;
    border-style:none;
    outline: none;
    box-shadow: none;
    background-color:#000033;
    border-color:#000033
  }
  .topbarIconButton:focus {
    border:none;
    border-style:none;
    outline: none;
    box-shadow: none;
    border-color:#000033;
    background-color:#000033
  }
  .topbarIconButton:active {
    border:none;
    border-style:none;
    outline: none;
    box-shadow: none;
    border-color:#000033;
    background-color:#000033
  }
  .topbarIconButton:checked {
    border:none;
    border-style:none;
    outline: none;
    box-shadow: none;
    border-color:#000033;
    background-color:#000033
  }
  .topbarIconButton:disabled {
    border:none;
    border-style:none;
    outline: none;
    box-shadow: none;
    border-color:#000033;
    background-color:#000033
  }
  .topbarIconButton:hover {
    border:none;
    box-shadow: none;
    border-style:none;
    outline: none;
    border-color:#000033;
    background-color:#000033
  }

  .svc-side-button-main:focus {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    color:black;
    outline: none;
    border-style: none;
    border-width:1px;
    box-shadow:none;
  }

  .bg-svc-side {
    background-color: #f2f2f2;
    color:black;
  }

  .svcAppName {
    color:white;
    font-size:x-large;
    padding-right: 40px;
    padding-bottom: 1px;
    text-align: center;
  }
  .svcSideTitle {
    color: black;
    font-size:x-large;
    padding-top: 40px;
  }
  .svcSideHeading {
    color: black;
    font-size:large;
    padding-top: 10px;
  }
  .svcSideItem {
    color: black;
    font-size:medium;
  }
  .whse-button {
    background-color: #000033;
    border-color: #000033;
    color:white;
    align-items: right
  }

  .whse-button-cell {
    align-items: right
  }

  .svc-button-bar {
    border: 1px #0f7698;
    background-color: #f2f2f2;
    border-color: #0f7698;
    width:100%
  }
  .table.svc-button-bar {
    border: 1px #0f7698;
    background-color: #f2f2f2;
    border-color: #0f7698;
    width:100%
  }
  .td.svc-button-bar {
    border: 1px #0f7698;
    background-color: #f2f2f2;
    border-color: #0f7698;
    width:100%
  }
  .tr.svc-button-bar {
    border: 1px #0f7698;
    background-color: #f2f2f2;
    border-color: #0f7698;
    width:100%
  }

  .popover-arrow {
    display: none !important;
}
.popover{width:auto!important; max-width: none}

  .btn-primary {
    border:none;
    border-style:none;
    outline: none;
    box-shadow: none;
    background-color:#000033;
    border-color:#000033    
  }
  .btn-primary:active {
    border:none;
    border-style:none;
    outline: none;
    box-shadow: none;
    background-color:#000033;
    border-color:#000033
  }    
  .btn-primary:focus {
    border:none;
    border-style:none;
    outline: none;
    box-shadow: none;
    background-color:#000033;
    border-color:#000033
  }    
  .btn-primary:hover {
    border:none;
    border-style:none;
    outline: none;
    box-shadow: none;
    background-color:#000033;
    border-color:#000033
  }    
